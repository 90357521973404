import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { initializeAnalytics, trackPageView } from './analytics';
import { RoutesT } from './Routes';
import './App.css';

// Componente para rastrear as mudanças de rota
const AnalyticsTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Envia o evento de pageview sempre que a rota mudar
    trackPageView(location.pathname + location.search);
  }, [location]); // Dependência de location garante que o efeito será acionado em cada mudança de rota

  return null;
};

function App() {
  // Inicializa o Google Analytics assim que o componente for montado
  useEffect(() => {
    initializeAnalytics();
  }, []);

  return (
    <Router>
      {/* Adicionando o AnalyticsTracker para capturar mudanças de rota */}
      <AnalyticsTracker />
      <div className="App">
        <div className="app-container">
          <RoutesT />
        </div>
      </div>
    </Router>
  );
}

export default App;
