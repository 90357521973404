import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

// Lazy loading das páginas com importação padrão
const Home = lazy(() => import('../Pages/Home'));
const LoadingPage = lazy(() => import('../Pages/LoadingPage'));
const Sites = lazy(() => import('../Pages/Sites'));
const Freelance = lazy(() => import('../Pages/Freelance'));
const ContactPage = lazy(() => import('../Pages/ContactPage'));
const AboutPage = lazy(() => import('../Pages/AboutPage'));
const Restyling = lazy(() => import('../Pages/Restyling'));
const LandingPages = lazy(() => import('../Pages/LandingPages'));

export function RoutesT() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/loading" element={<LoadingPage />} />
        <Route path="/criacao-sites" element={<Sites />} />
        <Route path="/reestilizacao" element={<Restyling />} />
        <Route path="/landing-pages" element={<LandingPages />} />
        <Route path="/freelance" element={<Freelance />} />
        <Route path="/contato" element={<ContactPage />} />
        <Route path="/sobre" element={<AboutPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
}
