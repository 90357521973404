// analytics.tsx
import ReactGA from 'react-ga4';

export const initializeAnalytics = () => {
  ReactGA.initialize('G-06BT0TSG90'); // ID de medição
};

export const trackPageView = (path: string) => {
  const pageData = {
    hitType: 'pageview',
    page: path
  };
  //console.log('Enviando evento pageview para o GA4 com os dados:', pageData);
  ReactGA.send(pageData);
};


